<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import DefaultLayout from './DefaultLayout.vue'

export default defineComponent({
  name: 'TheLayout',
  components: {
    DefaultLayout
  },
  setup (_, { root }) {
    const layout = computed(() => root.$route.meta.layout || 'DefaultLayout')

    return {
      layout
      // route
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
