<template>
  <div class="dim" v-if="visible" @click.self="alertCloseBtn">
    <div class="c-confirm">
      <div class="c-confirm__header">
        <h2 class="c-confirm__header--title">{{ confirmTitle }}</h2>
        <div v-if="closeButtonHide">
          <button class="c-confirm__header--close" @click="alertCloseBtn"></button>
        </div>
      </div>
      <div class="c-confirm__content">
        <slot name="content">
          <div class="c-confirm__content-box" v-html="defaultContent"></div>
        </slot>
      </div>
      <div class="c-confirm__footer">
        <button class="button" @click="alertCloseBtn('send')">{{ submitTitle }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'CAlert',
  props: {
    confirmTitle: {
      type: String,
      default: ''
    },
    defaultContent: {
      type: String,
      default: ''
    },
    closeButtonHide: {
      type: Boolean,
      default: false
    },
    submitTitle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  setup (props, { emit }) {
    const alertCloseBtn = (e) => {
      if (e === 'send') emit('handleEvent')

      emit('update:visible', false)
    }

    return {
      alertCloseBtn
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "src/assets/scss/_styles.scss";

.dim{
  @include flexCenter();
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: $black-fade;
  z-index: 10;
}

.c-confirm{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  background: $white;
  border-radius: 12px;
  z-index: 11;
  /* header */
  &__header{
    padding: 34px 16px 12px;
    @include boxSizing();
    &--title{
      @include font(normal, bold, 18px, $text, 1.17);
      font-stretch: normal;
      letter-spacing: -0.5px;
      text-align: center;
    }
    /* close */
    &--close{
      position: absolute;
      top: 18px;
      right: 22px;
      &:before{
        content:"";
        display: block;
        transform: rotate(45deg);
        width: 1.4px;
        height: 18px;
        background-color: $text-disabled;
      }
      &:after{
        content:"";
        display: block;
        position: absolute;
        top:0;
        transform: rotate(135deg);
        width: 1.4px;
        height: 18px;
        background-color: $text-disabled;
      }
    }
  }

  /* content */
  &__content{
    padding: 0 16px;
    @include font(normal, normal, 16px, $text-sub, 1.31);
    font-stretch: normal;
    letter-spacing: -0.5px;
    text-align: center;
    @include boxSizing();
    &-box{
      padding: 0 16px;
      @include font(normal, normal, 16px, $text-sub, 1.31);
      font-stretch: normal;
      letter-spacing: -0.5px;
      text-align: center;
      @include boxSizing();
    }
  }
  /* footer */
  &__footer{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 24px 16px 16px;
    .button{
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border: 1px solid $primary;
      background-color: $primary;
      @include font(normal, bold, 16px, $white, normal);
      text-align: center;
      &.outline-grey{
        @include blockCustomStyle($border-input,$white,$text-sub);
      }
    }
  }
}
</style>
