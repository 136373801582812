<template>
  <footer class="footer">
    <div class="footer__img">
      <img src="../../assets/images/ci.png" alt="ci" />
    </div>
  </footer>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TheFooter',
  setup () {
    return {}
  }
})
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;

  &__img {
    width: 90px;
    height: 30px;

    img {
      width: 100%;
    }
  }
}
</style>
