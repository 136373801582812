<template>
  <div id="app">
    <the-layout>
      <keep-alive v-if="$route.path.indexOf('fullSchedule') > 0">
        <router-view />
      </keep-alive>

      <router-view v-else />
    </the-layout>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TheLayout from '@/layouts/TheLayout.vue'

export default defineComponent({
  name: 'App',
  components: {
    TheLayout
  }
})

</script>

<style lang="scss">
//
</style>
