<template>
  <header id="header" class="header" :class="header.useLogo ? '': 'white'">
    <div class="header-inner">
      <template v-if="header.useLogo">
        <h1 class="header-logo">
          <div class="header-logo__link">
            <img
              src="@/assets/images/img_header_clean24.png"
              alt="청소24"
              class="header-logo__img"
            >
          </div>
        </h1>
        <button type="button"
                class="header-logout__btn"
                v-if="$route.name === 'main'"
                @click="handleClickLogout"
        >
          <img src="@/assets/icons/logout.png" alt="">
          <span class="blind">로그아웃</span>
        </button>
      </template>

      <template v-else>
        <button type="button" class="header-back__btn" @click="historyBack">
          <img src="@/assets/images/ico_header_btn-back.svg" alt="">
          <span class="blind">뒤로가기</span>
        </button>

        <template v-if="header.title !== ''">
          <div class="header-title">
            <span class="header-title__txt">{{header.title}}</span>
          </div>
        </template>
      </template>
    </div>

    <c-confirm
        submit-btn-text="로그아웃"
        @click="logout"
        :is-show-close-btn="false"
        :is-show-cancel-btn="true"
        :visible.sync="showConfirm"
    >
      <template slot="content">
        로그아웃 하시겠습니까?
      </template>
    </c-confirm>
  </header>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import CConfirm from '@/components/ui/CConfirm'

export default defineComponent({
  name: 'TheHeader',
  components: { CConfirm },
  setup (_, { root }) {
    const header = computed(() => root.$store.state.layout.header)
    const showConfirm = ref(false)

    const historyBack = () => {
      root.$router.go(-1)
    }

    const handleClickLogout = () => {
      showConfirm.value = true
    }

    const logout = async () => {
      await root.$store.dispatch('auth/resetAuth')
      showConfirm.value = false
      root.$router.go(0)
    }

    return {
      header,
      showConfirm,
      logout,
      historyBack,
      handleClickLogout
    }
  }
})
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid transparent;
  background: $background;

  &.white {
    background: #fff;
    border-bottom: 1px solid $border;
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
  }

  &-logo {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 16px;
    margin-top: -11px;
    font-size: 0;

    &__link {
      display: block;
    }

    &__img {
      width: 76px;
    }
  }

  &-logout {
    &__btn {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 14px;
      margin-top: -14px;
      width: 16px;
      height: 16px;
      text-align: left;

      img {
        width: 100%;
      }
    }
  }

  &-back {
    &__btn {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 14px;
      margin-top: -14px;
      width: 28px;
      height: 28px;
      text-align: left;

      img {
        width: 100%;
      }
    }
  }

  &-title {
    font-size: 0;
    text-align: center;

    &__txt {
      font: {
        size: 18px;
        weight: bold;
      };
      color: $text;
      letter-spacing: -0.5px;
    }
  }
}
</style>
