const layout = {
  namespaced: 'layout',
  state: {
    header: {
      useLogo: false,
      title: ''
    },
    footer: {
      useFooter: false
    },
    showPopup: false,
    popupText: '',
    popupTitle: '',
  },
  mutations: {
    SET_HEADER (state, header) {
      state.header = header
    },
    SET_FOOTER (state, footer) {
      state.footer = footer
    },
    SET_SHOW_POPUP (state, data) {
      state.showPopup = data
    },
    SET_POPUP_TEXT (state, data) {
      state.popupText = data
    },
    SET_POPUP_TITLE (state, data) {
      state.popupTitle = data
    }
  },
  actions: {
    setHeader ({ commit }, payload) {
      commit('SET_HEADER', payload)
    },
    setFooter ({ commit }, payload) {
      commit('SET_FOOTER', payload)
    },
    setShowPopup ({ commit }, payload) {
      commit('SET_SHOW_POPUP', payload)
    },
    setPopupText ({ commit }, payload) {
      commit('SET_POPUP_TEXT', payload)
    },
    setPopupTitle ({ commit }, payload) {
      commit('SET_POPUP_TITLE', payload)
    }
  }
}

export default layout
