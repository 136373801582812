import store from '@/store'

export default [
  {
    path: '/',
    redirect: {
      name: 'main'
    }
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/Main'),
    meta: {
      useLogo: true,
      useBackground: true,
      useFooter: true
    }
  },
  {
    path: '/modifyTime',
    name: 'modifyTime',
    component: () => import('../views/ModifyTime'),
    meta: {
      headerTitle: '수정요청'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      useLogo: true,
      useBackground: true,
      useFooter: true
    }
  },
  {
    path: '/password/phoneAuth',
    name: 'PhoneAuth',
    component: () => import('../views/PhoneAuth'),
    meta: {
      headerTitle: '휴대전화번호 인증'
    },
    beforeEnter (to, from, next) {
      const userKey = store.getters['password/getUserKey']

      if (userKey && userKey.length) {
        next()
      } else {
        next('/password/passwordInit')
      }
    }
  },
  {
    path: '/password/passwordInit',
    name: 'PasswordInit',
    component: () => import('../views/PasswordInit'),
    meta: {
      headerTitle: '비밀번호 초기화'
    }
  },
  {
    path: '/password/passwordSetting',
    name: 'PasswordSetting',
    component: () => import('../views/PasswordSetting'),
    meta: {
      headerTitle: '비밀번호 설정'
    },
    beforeEnter (to, from, next) {
      const userKey = store.getters['password/getUserKey']

      if (userKey && userKey.length) {
        next()
      } else {
        next('/password/passwordInit')
      }
    }
  },
  {
    path: '/password/passwordChange',
    name: 'PasswordChange',
    component: () => import('../views/PasswordChange'),
    meta: {
      headerTitle: '비밀번호 변경'
    }
  },
  {
    path: '/fullSchedule',
    name: 'FullSchedule',
    component: () => import('../views/FullSchedule'),
    meta: {
      headerTitle: '전체일정',
      useBackground: true
    }
  },
  {
    path: '/fullSchedule/detail',
    name: 'ScheduleDetail',
    component: () => import('../views/scheduleDetail'),
    meta: {
      headerTitle: '상세보기'
    }
  },
  {
    path: '/todayWork',
    name: 'TodayWork',
    component: () => import('../views/TodayWork'),
    meta: {
      headerTitle: '오늘의 근무'
    }
  },
  {
    path: '/todayWork/modifyTime',
    name: 'WorkModifyTime',
    component: () => import('../views/WorkModifyTime'),
    meta: {
      headerTitle: '수정요청'
    }
  },
  {
    path: '/sample',
    name: 'Sample',
    component: () => import('../views/Sample'),
    meta: {
      headerTitle: '샘플테스트'
    }
  }
]
