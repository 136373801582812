<template>
  <div class="default-layout">
    <the-header />

    <main class="container">
      <slot />
    </main>

    <the-footer v-if="$route.meta.useFooter === true" />
    <c-alert :visible.sync="showPopup"
             :default-content="popupText"
             :confirm-title="popupTitle"
             submit-title="확인">
    </c-alert>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import TheHeader from '@/layouts/include/TheHeader.vue'
import TheFooter from '@/layouts/include/TheFooter'
import CAlert from '@/components/ui/CAlert'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    TheFooter,
    TheHeader,
    CAlert
  },
  setup (_, { root }) {
    const store = root.$store
    const showPopup = ref(computed({
      get: () => {
        return store.state.layout.showPopup
      },
      set: (val) => {
        store.dispatch('layout/setShowPopup', val)
      }
    }))
    const popupTitle = ref(computed(() => store.state.layout.popupTitle))
    const popupText = ref(computed(() => store.state.layout.popupText))
    return {
      showPopup,
      popupText,
      popupTitle
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  border-top: 8px solid #eaedf3;
}
</style>
