const password = {
  namespaced: 'password',
  state: {
    userInfo: {
      name: '',
      cellPhone: ''
    },
    workerEncrypted: ''
  },
  getters: {
    getUserInfo (state) {
      return state.userInfo
    },
    getUserKey (state) {
      return state.workerEncrypted
    }
  },
  mutations: {
    SET_USERINFO (state, userInfo) {
      state.userInfo = userInfo
    },
    SET_USERKEY (state, userKey) {
      state.workerEncrypted = userKey
    }
  },
  actions: {
    setUserInfo ({ commit }, payload) {
      commit('SET_USERINFO', payload)
    },
    setUserKey ({ commit }, payload) {
      commit('SET_USERKEY', payload)
    }
  }
}

export default password
