import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedStore from 'vuex-persistedstate'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  getters: {
    isAuth: (state) =>
      !!(
        state.auth.clientId &&
        state.auth.clientSecret &&
        state.auth.workerEncrypted &&
        state.auth.cleanEncrypted
      )
  },
  modules: modules,
  plugins: [
    createPersistedStore({
      paths: ['worker']
    })
  ]
})
