import Vue from 'vue'
import store from '@/store'
import routes from './path'
import { getLocalStorage, getSessionStorage } from '@/composables/session.js'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import PhoneAuth from '../views/PhoneAuth.vue'
import PasswordInit from '../views/PasswordInit.vue'
import PasswordSetting from '../views/PasswordSetting.vue'
import PasswordChange from '../views/PasswordChange.vue'
import Sample from '../views/Sample.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)
NProgress.configure({ showSpinner: false })

/*
* router mata
*  - useLogo: 로고 사용 여부 (type: Boolean)
*  - useBackground: 배경색 사용 여부 (type: Boolean)
*  - useFooter: footer 사용 여부 (type: Boolean)
*  - headerTitle: 타이틀 (type: String)
*/
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to:Route, from:Route, next) => {
  NProgress.start()
  setLayout(to)
  const result = await authCheck()
  if (store.state.auth.firstLoading) {
    if (!result && to.path !== '/login') {
      next({
        path: '/login'
      })
    } else if (result && to.path === '/login') {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

const authCheck = async () => {
  const appAuth = getLocalStorage('appAuth') || getSessionStorage('appAuth')
  if (appAuth) {
    await store.dispatch('auth/setAuth', appAuth)
    return true
  } else {
    return false
  }
}

const setLayout = (to: Route) => {
  if (to.meta && to.meta.useLogo) {
    store.dispatch('layout/setHeader', {
      useLogo: true
    })
  } else {
    store.dispatch('layout/setHeader', {
      useLogo: false,
      title: to.meta && to.meta.headerTitle
    })
  }

  if (to.meta && to.meta.useFooter) {
    store.dispatch('layout/setFooter', {
      useFooter: true
    })
  } else {
    store.dispatch('layout/setFooter', {
      useFooter: false
    })
  }

  if (to.meta && to.meta.useBackground) {
    document.body.classList.add('bg')
  } else {
    document.body.classList.remove('bg')
  }
}

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
