const state = {
  workerId: '',
  workerName: '',
  workplace: null,
  attendance: {
    id: '',
    workInAt: null,
    workOutAt: null,
    workInModifyRequest: null,
    workOutModifyRequest: null
  },
  now: '',
  dayOfWeek: ''
}

const mutations = {
  SET_WORKER_ID: (state, workerId) => {
    state.workerId = workerId
  },
  SET_WORKER_NAME: (state, workerName) => {
    state.workerName = workerName
  },
  SET_WORKPLACE: (state, workplace) => {
    state.workplace = workplace
  },
  SET_ATTENDANCE: (state, attendance) => {
    state.attendance = attendance
  },
  SET_WORK_IN_MODIFY_REQUEST: (state, request) => {
    state.attendance.workInModifyRequest = request
  },
  SET_WORK_OUT_MODIFY_REQUEST: (state, request) => {
    state.attendance.workOutModifyRequest = request
  },
  SET_NOW: (state, now) => {
    state.now = now
  },
  SET_DAY_OF_WEEK: (state, dayOfWeek) => {
    state.dayOfWeek = dayOfWeek
  }

}

const actions = {
  setWorkerInfo ({ commit }, data) {
    commit('SET_WORKER_ID', data.id)
    commit('SET_WORKER_NAME', data.name)
    commit('SET_WORKPLACE', data.workplace)
    commit('SET_ATTENDANCE', data.attendance)
    commit('SET_NOW', data.now)
    commit('SET_DAY_OF_WEEK', data.dayOfWeek)
  },
  setWorkInModifyRequest ({ commit }, data) {
    commit('SET_WORK_IN_MODIFY_REQUEST', data)
  },
  setWorkOutModifyRequest ({ commit }, data) {
    commit('SET_WORK_OUT_MODIFY_REQUEST', data)
  },
  setAttendance ({ commit }, data) {
    commit('SET_ATTENDANCE', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
