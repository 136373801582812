// Local Storage
export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : null
}

export const setLocalStorage = (key, object) => {
  return localStorage.setItem(key, JSON.stringify(object))
}

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key)
}

// Session Storage
export const getSessionStorage = (key) => {
  return sessionStorage.getItem(key)
    ? JSON.parse(sessionStorage.getItem(key))
    : null
}

export const setSessionStorage = (key, object) => {
  return sessionStorage.setItem(key, JSON.stringify(object))
}

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key)
}
