import {
  setLocalStorage,
  setSessionStorage,
  removeLocalStorage,
  removeSessionStorage
} from '@/composables/session'

const auth = {
  namespaced: 'auth',
  state: {
    workerEncrypted: null,
    name: null,
    employeeNumber: null,
    cleanEncrypted: null,
    clientId: null,
    clientSecret: null,
    firstLoading: true
  },
  mutations: {
    SET_WORKER_ENCRYPTED: (state, workerEncrypted) => {
      state.workerEncrypted = workerEncrypted
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_EMPLOYEE_NUMBER: (state, employeeNumber) => {
      state.employeeNumber = employeeNumber
    },
    SET_CLEAN_ENCRYPTED: (state, cleanEncrypted) => {
      state.cleanEncrypted = cleanEncrypted
    },
    SET_CLIENT_ID: (state, clientId) => {
      state.clientId = clientId
    },
    SET_CLIENT_SECRET: (state, clientSecret) => {
      state.clientSecret = clientSecret
    },
    SET_FIRST_LOADING: (state, firstLoading) => {
      state.firstLoading = firstLoading
    }
  },
  actions: {
    setTempAuth ({ commit }, data) {
      commit('SET_WORKER_ENCRYPTED', data.workerEncrypted)
      commit('SET_NAME', data.name)
    },
    setAuth ({ commit }, data) {
      commit('SET_WORKER_ENCRYPTED', data.workerEncrypted)
      commit('SET_NAME', data.name)
      commit('SET_EMPLOYEE_NUMBER', data.employeeNumber)
      commit('SET_CLEAN_ENCRYPTED', data.cleanEncrypted)
      commit('SET_CLIENT_ID', data.clientId)
      commit('SET_CLIENT_SECRET', data.clientSecret)
    },
    setAuthLocalStorage ({ commit }, data) {
      setLocalStorage('appAuth', data)
    },
    setAuthSessionStorage ({ commit }, data) {
      setSessionStorage('appAuth', data)
    },
    resetAuth ({ commit }) {
      commit('SET_WORKER_ENCRYPTED', null)
      commit('SET_NAME', null)
      commit('SET_EMPLOYEE_NUMBER', null)
      commit('SET_CLEAN_ENCRYPTED', null)
      commit('SET_CLIENT_ID', null)
      commit('SET_CLIENT_SECRET', null)
      removeLocalStorage('appAuth')
      removeSessionStorage('appAuth')
    },
    setFirstLoading ({ commit }, firstLoading) {
      commit('SET_FIRST_LOADING', firstLoading)
    }
  }
}

export default auth
